import { type PolicyDefinition } from './types'

export const announcementPolicy = ((user) => ({
  canList() {
    return this.canRead()
  },

  canCreate() {
    return user.isSuperAdmin || user.isAdmin || user.isAnnouncer
  },

  canRead() {
    return user.isLoggedIn
  },

  canUpdate() {
    return this.canCreate()
  },

  canDestroy() {
    return this.canUpdate()
  },
})) satisfies PolicyDefinition
