import type { PolicyActions } from '@/composables/policies/types'

import { adminPolicy } from '@/composables/policies/adminPolicy'
import { buildingPolicy } from '@/composables/policies/buildingPolicy'
import { departmentPolicy } from '@/composables/policies/departmentPolicy'
import { kioskPolicy } from '@/composables/policies/kioskPolicy'
import { spacePolicy } from '@/composables/policies/spacePolicy'
import { userPolicy } from '@/composables/policies/userPolicy'
import { announcementPolicy } from '@/composables/policies/announcementPolicy'
import { userGroupPolicy } from '@/composables/policies/userGroupPolicy'
import { authPolicy } from '@/composables/policies/authPolicy'
import { dashboardPolicy } from '@/composables/policies/dashboardPolicy'
import { settingPolicy } from '@/composables/policies/settingPolicy'
import { bookableItemConfigPolicy } from '@/composables/policies/bookableItemConfigPolicy'
import { visitorPolicy } from '@/composables/policies/visitorPolicy'
import { myComeenPolicy } from '@/composables/policies/myComeenPolicy'
import { onboardingPolicy } from '@/composables/policies/onboardingPolicy'
import { adminDashboardPolicy } from '@/composables/policies/adminDashboardPolicy'
import { meetingRoomPolicy } from '@/composables/policies/meetingRoomPolicy'
import { oAuthPolicy } from '@/composables/policies/oauthPolicy'
import { roomDashboardPolicy } from '@/composables/policies/roomDashboardPolicy'

export const usePolicies = () => {
  const auth = useAuth()

  return {
    adminDashboard: adminDashboardPolicy(toValue(auth.user)),
    auth: authPolicy(toValue(auth.user)),
    announcement: announcementPolicy(toValue(auth.user)),
    admin: adminPolicy(toValue(auth.user)),
    bookableItemConfig: bookableItemConfigPolicy(toValue(auth.user)),
    building: buildingPolicy(toValue(auth.user)),
    dashboard: dashboardPolicy(toValue(auth.user)),
    department: departmentPolicy(toValue(auth.user)),
    kiosk: kioskPolicy(toValue(auth.user)),
    meetingRoom: meetingRoomPolicy(toValue(auth.user)),
    myComeen: myComeenPolicy(toValue(auth.user)),
    onboarding: onboardingPolicy(toValue(auth.user)),
    setting: settingPolicy(toValue(auth.user)),
    space: spacePolicy(toValue(auth.user)),
    user: userPolicy(toValue(auth.user)),
    userGroup: userGroupPolicy(toValue(auth.user)),
    visitor: visitorPolicy(toValue(auth.user)),
    oauth: oAuthPolicy(toValue(auth.user)),
    roomDashboard: roomDashboardPolicy(toValue(auth.user)),
  } satisfies Record<string, PolicyActions>
}

export type Policies = ReturnType<typeof usePolicies>
export type PolicyName = keyof Policies
export type PolicyAction = keyof Policies[PolicyName]
