import { type PolicyDefinition } from './types'

export const kioskPolicy = ((user) => ({
  canList() {
    return this.canRead()
  },

  canCreate() {
    return user.isSuperAdmin || user.isAdmin
  },

  canRead() {
    return user.isSuperAdmin || user.isAdmin || user.isDashboard
  },

  canUpdate() {
    return this.canCreate()
  },

  canDestroy() {
    return this.canCreate()
  },
})) satisfies PolicyDefinition
