import { type PolicyDefinition } from './types'

export const departmentPolicy = ((user) => ({
  canList() {
    return this.canRead()
  },

  canCreate() {
    return user.isSuperAdmin || user.isAdmin
  },

  canRead() {
    return user.isSuperAdmin || user.isAdmin
  },

  canUpdate() {
    return this.canCreate()
  },

  canDestroy() {
    return this.canCreate()
  },
})) satisfies PolicyDefinition
