import { type PolicyDefinition } from './types'

export const authPolicy = ((user) => ({
  canLogin() {
    return true
  },
  canLogout() {
    return user.isLoggedIn
  },
})) satisfies PolicyDefinition
